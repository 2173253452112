<template>
    <Header />
    <MobileMenu />
    <div class="outside-container" v-if="$isLogin()">
        <div class="container">
            <div class="left-box"></div>
            <div class="flex section-wrap">
                <transition name="slide">
                    <Section v-if="!isSectionHide" />
                </transition>
                <div class="button-wrap">
                    <div class="section-hide-button flex" @click="hideSection">
                        <div
                            class="down-triangle-icon"
                            :class="{ 'icon-hide': isSectionHide }"
                        ></div>
                    </div>
                </div>
            </div>

            <div
                class="main-content"
                :class="{ 'section-hide': isSectionHide }"
            >
                <router-view v-slot="{ Component }">
                    <keep-alive :include="$store.state.keepAliveIncludes">
                        <component
                            v-if="$route.meta.isKeepAlive"
                            :is="Component"
                            :key="
                                $route.meta.pageMode
                                    ? $route.name
                                    : $route.fullPath
                            "
                        />
                    </keep-alive>
                    <component
                        v-if="!$route.meta.isKeepAlive"
                        :is="Component"
                        :key="
                            $route.meta.pageMode ? $route.name : $route.fullPath
                        "
                    />
                </router-view>
            </div>
        </div>
    </div>
    <div v-if="$store.state.showErrorModal">
        <ErrorModal />
    </div>
    <Tooltip />
    <Loading />
    <RefreshToken />
    <div v-if="$isMobile()">
        <GoTopButton />
    </div>
</template>

<script>
import { lazyLoadComponent } from '@/assets/javascripts/util'
import Header from '@/containers/Header.vue'
import RefreshToken from '@/components/RefreshToken'
import Tooltip from '@/components/Tooltip'
import Loading from '@/containers/Loading'
import Section from '@/containers/Section'
import MobileMenu from '@/containers/MobileMenu'
const GoTopButton = lazyLoadComponent('components/GoTopButton')
const ErrorModal = lazyLoadComponent('containers/ErrorModal')

export default {
    name: 'NormalLayout',
    components: {
        Tooltip,
        Header,
        Section,
        MobileMenu,
        Loading,
        ErrorModal,
        GoTopButton,
        RefreshToken
    },
    data() {
        return {
            isSectionHide: true
        }
    },
    methods: {
        changeSectionHideData: function () {
            if (window.innerWidth < 1281) {
                this.isSectionHide = true
            }
        },
        updateSectionHidden: function () {
            this.$store.commit('set', {
                isSectionHidden: this.isSectionHide
            })
        },
        hideSection: function () {
            this.$setGaEvent(
                this.isSectionHide ? 'sectionShow' : 'sectionHide',
                'click-Section'
            )
            this.isSectionHide = !this.isSectionHide
        }
    },
    watch: {
        isSectionHide: function () {
            this.updateSectionHidden()
        },
        //避免人力查詢權限還沒更新造成功能上線已登入使用者無法進任何頁面，上線之後過段時間移除↓
        '$store.state.permissions': {
            handler() {
                this.$reLoginGetPermissions()
            },
            deep: true,
            immediate: true
        }
        //避免人力查詢權限還沒更新造成功能上線已登入使用者無法進任何頁面，上線之後過段時間移除↑
    },
    created() {
        this.$hideLoading()
        this.changeSectionHideData()
        this.updateSectionHidden()
        window.addEventListener('resize', this.changeSectionHideData, true)
    }
}
</script>

<style lang="scss" scoped>
.left-box {
    position: absolute;
    right: calc(
        #{$primary-central-width} + (100vw - #{$primary-central-width}) / 2
    );
    width: 50vw;
    height: 100vh;
    z-index: 1;
    background-color: $primary-grey;
}

.button-wrap {
    position: relative;
    width: 35px;
    height: 180px;
    border-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0)
        )
        30;
    border-left: 3px solid $primary-black;
    border-right: 0px;
    .section-hide-button {
        cursor: pointer;
        .down-triangle-icon {
            width: 12px;
            height: 12px;
            transform: rotate(90deg);
            &.icon-hide {
                transform: rotate(270deg);
            }
        }
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -10px;
        top: 40px;
        height: 50px;
        width: 30px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: $primary-grey;
        box-shadow: 3px 1px 4px rgba(0, 0, 0, 0.1);
        margin-right: 10px;
    }
}

@media screen and (max-width: 1280px) {
    .section-wrap {
        display: none;
    }

    .left-box {
        display: none;
    }
}

.main-content {
    transition-duration: 0.6s;
    width: calc(#{$secondary-central-width} + #{$secondary-section-width});
    width: calc(
        Min(#{$secondary-central-width}, 100%) + #{$secondary-section-width}
    );

    max-width: #{$secondary-central-width};
    &.section-hide {
        width: 100%;
        max-width: calc(
            #{$secondary-central-width} + #{$secondary-section-width}
        );
    }
    background-color: $primary-grey;

    @media screen and (min-width: 576px) and (max-width: 1280px) {
        &.section-hide {
            width: calc(#{$fourth-central-width} - #{$third-section-width});
            max-width: $primary-central-width;
        }
        width: calc(#{$fourth-central-width} - #{$third-section-width});
    }
    @media screen and (min-width: 0px) and (max-width: 575px) {
        &.section-hide {
            width: calc(#{$fifth-central-width} - #{$third-section-width});
        }
        width: calc(#{$fifth-central-width} - #{$third-section-width});
    }
}

.slide-enter-active {
    transition: margin-left 0.5s;
    overflow: hidden;
}
.slide-leave-active {
    transition: margin-left 0.5s;
    overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
    margin-left: calc(-1 * #{$secondary-section-width});
}

.slide-enter-to,
.slide-leave-from {
    margin-left: 0px;
}
</style>
